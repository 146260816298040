<template>
  <v-list-item>
    <v-list-item-content>
      <div class="d-flex">
        <v-btn
          small
          class="mr-4"
          color="primary"
          :to="{ name: 'site-admin-profile' }"
        >
          {{'data.profile'| t}}
        </v-btn>

        <v-btn small class="mr-4" color="primary" @click="$helper.account.logout()">
          {{'action.logout'| t}}
        </v-btn>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {

}
</script>

<style lang="sass" type="text/sass" scoped></style>